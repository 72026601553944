import { DEFAULT_STORE_TYPE, STORE_TYPES } from "~/server/constants/store";
import { nameSchema, phoneSchema } from "~/validations/shared";

export const loginInputSchema = z.object({
  phone: phoneSchema,
});

export const generateAuthenticationOptionsInputSchema = z.object({
  phone: phoneSchema,
});

const deviceSchema = z.enum(["desktop", "mobile"]);

export const generateRegistrationOptionsInputSchema = z.object({
  phone: phoneSchema,
  name: nameSchema,
  device: deviceSchema,
});

export const registerInputSchema = generateRegistrationOptionsInputSchema.merge(
  z.object({
    storeName: z.string().min(1),
    address: z.string().min(1),
    type: z.enum(STORE_TYPES).default(DEFAULT_STORE_TYPE),
  }),
);

export const passkeyResetGenerateRegistrationOptionsInputSchema = z.object({
  token: z.string().length(40),
  oldPhone: phoneSchema,
  newPhone: phoneSchema,
  device: deviceSchema,
});

export const loginWithTokenInputSchema = z.object({
  phone: z.string(),
  token: z.string(),
});
