<script setup lang="ts">
import { toTypedSchema } from "@vee-validate/zod";
import { useForm } from "vee-validate";
import { UIFormField } from "~/components/UI/Form";
import { loginInputSchema } from "~/validations/auth";

const { handleSubmit, resetForm } = useForm({
  validationSchema: toTypedSchema(loginInputSchema),
});

onMounted(() => {
  resetForm({
    values: {
      phone: useCookie("phone").value?.toString() || "",
    },
  });
});

const isLoading = ref(false);
const error = ref("");

const onSubmit = handleSubmit(async ({ phone }) => {
  isLoading.value = true;

  try {
    await login({ phone });
  } catch (err) {
    isLoading.value = false;

    if (err instanceof Error) {
      if (err.message.includes("Too many requests")) {
        error.value = t("tooManyRequests");
        return;
      }

      if (err.message.includes("User not found")) {
        error.value = t("userNotFound");
        return;
      }

      if (err.message.includes("User not verified")) {
        error.value = t("userNotVerified");
        return;
      }

      if (err.message.includes("No passkey found for user")) {
        error.value = t("passkeyNotFound");
        return;
      }
    }

    error.value = t("somethingWentWrong");
    return;
  }

  useCookie("phone", {
    maxAge: 60 * 60 * 24 * 7, // 7 days
    sameSite: "lax",
  }).value = phone;

  useIsAuthed().value = true;

  return navigateTo("/app/sales");
});

/* ***************** SEO ***************** */
const { t } = useI18n({
  useScope: "local",
});

const TITLE = t("title");
const DESCRIPTION = t("description");

useSeoMeta({
  title: TITLE,
  description: DESCRIPTION,

  ogTitle: TITLE,
  ogDescription: DESCRIPTION,

  twitterTitle: TITLE,
  twitterDescription: DESCRIPTION,
});
</script>

<template>
  <div>
    <h1 class="text-4xl font-bold text-primary">
      {{ t("title") }}
    </h1>
    <p class="mt-2 flex flex-wrap gap-2">
      <span>
        {{ t("newUser") }}
      </span>
      <NuxtLink :to="'/auth/join-us'" class="text-secondary underline">
        {{ t("joinUs") }}
      </NuxtLink>
    </p>
    <UIAlert
      v-if="error"
      variant="error"
      :description="error"
      dismiss-label="Fermer"
      :on-dismiss="() => (error = '')"
      class="my-4"
    />
    <form class="mt-8 flex flex-col gap-8" @submit="onSubmit">
      <UIFormField v-slot="{ componentField }" name="phone">
        <UIFormItem>
          <UIFormLabel>{{ t("phone") }}</UIFormLabel>
          <UIFormControl>
            <UIInput type="tel" v-bind="componentField" />
          </UIFormControl>
          <UIFormMessage />
        </UIFormItem>
      </UIFormField>

      <UIButton
        type="submit"
        :label="t('loginBtn')"
        :is-loading="isLoading"
        :disabled="isLoading"
      />
    </form>
  </div>
</template>

<i18n lang="json">
{
  "en": {
    "title": "Login",
    "description": "Login to your account to access all the features of our web app.",
    "newUser": "New user?",
    "joinUs": "Join us",
    "loginBtn": "Login",
    "phone": "Phone number",
    "tooManyRequests": "Too many requests. Try again later.",
    "somethingWentWrong": "Something went wrong. Try again later.",
    "userNotVerified": "User not verified.",
    "userNotFound": "User not found.",
    "passkeyNotFound": "Passkey not found."
  },
  "fr": {
    "title": "S'identifier",
    "description": "Connectez-vous à votre compte pour accéder à toutes les fonctionnalités de notre application web.",
    "newUser": "Nouvel utilisateur?",
    "joinUs": "Rejoignez-nous",
    "loginBtn": "Connexion",
    "phone": "Numéro de téléphone",
    "tooManyRequests": "Trop de requêtes. Réessayez plus tard.",
    "somethingWentWrong": "Quelque chose s'est mal passé. Réessayez plus tard.",
    "userNotVerified": "Utilisateur non vérifié.",
    "userNotFound": "Utilisateur non trouvé.",
    "passkeyNotFound": "Passkey non trouvé."
  }
}
</i18n>
